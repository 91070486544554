/* routes/Help.svelte generated by Svelte v3.50.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	prevent_default,
	run_all,
	safe_not_equal,
	space,
	stop_propagation,
	transition_in,
	transition_out
} from "svelte/internal";

import Info from "../components/Info.svelte";

function create_fragment(ctx) {
	let div5;
	let div0;
	let h10;
	let t1;
	let ul1;
	let li0;
	let a0;
	let t3;
	let li1;
	let a1;
	let t5;
	let li2;
	let a2;
	let t7;
	let li3;
	let t8;
	let li9;
	let a3;
	let t10;
	let ul0;
	let li4;
	let a4;
	let t12;
	let li5;
	let a5;
	let t14;
	let li6;
	let a6;
	let t16;
	let li7;
	let a7;
	let t18;
	let li8;
	let a8;
	let t20;
	let div1;
	let t33;
	let div2;
	let t37;
	let div3;
	let h13;
	let t39;
	let p5;
	let t41;
	let p6;
	let t43;
	let table0;
	let t59;
	let info0;
	let t60;
	let p7;
	let t62;
	let p8;
	let t64;
	let p9;
	let t66;
	let div4;
	let h14;
	let t68;
	let p10;
	let t70;
	let p11;
	let t72;
	let table1;
	let t108;
	let info1;
	let t109;
	let p12;
	let t111;
	let p13;
	let t113;
	let h40;
	let t115;
	let p14;
	let t119;
	let h41;
	let t121;
	let p15;
	let t123;
	let p16;
	let t127;
	let p17;
	let t129;
	let h42;
	let t131;
	let p18;
	let t133;
	let table2;
	let t151;
	let p19;
	let t153;
	let h43;
	let t155;
	let p20;
	let t157;
	let table3;
	let t193;
	let p21;
	let t195;
	let h44;
	let t197;
	let p22;
	let t199;
	let p23;
	let t201;
	let p24;
	let current;
	let mounted;
	let dispose;

	info0 = new Info({
			props: {
				message: "The headers should NOT be included in your spreadsheet only the teams."
			}
		});

	info1 = new Info({
			props: {
				message: "The headers should NOT be included in your spreadsheet only the games."
			}
		});

	return {
		c() {
			div5 = element("div");
			div0 = element("div");
			h10 = element("h1");
			h10.textContent = "Contents";
			t1 = space();
			ul1 = element("ul");
			li0 = element("li");
			a0 = element("a");
			a0.textContent = "What are we solving?";
			t3 = space();
			li1 = element("li");
			a1 = element("a");
			a1.textContent = "What is .csv?";
			t5 = space();
			li2 = element("li");
			a2 = element("a");
			a2.textContent = "Teams";
			t7 = space();
			li3 = element("li");
			t8 = space();
			li9 = element("li");
			a3 = element("a");
			a3.textContent = "Draw";
			t10 = space();
			ul0 = element("ul");
			li4 = element("li");
			a4 = element("a");
			a4.textContent = "Time Format";
			t12 = space();
			li5 = element("li");
			a5 = element("a");
			a5.textContent = "Placeholders";
			t14 = space();
			li6 = element("li");
			a6 = element("a");
			a6.textContent = "Finals";
			t16 = space();
			li7 = element("li");
			a7 = element("a");
			a7.textContent = "Semi Finals";
			t18 = space();
			li8 = element("li");
			a8 = element("a");
			a8.textContent = "General Pools";
			t20 = space();
			div1 = element("div");

			div1.innerHTML = `<h1 class="title" id="goal">What are we solving?</h1> 
    <p class="svelte-1rnwl77">This website helps you publish and manage a Canoe Polo competition. Allowing you to sit back
      and enjoy the competition on the day!</p> 
    <p class="svelte-1rnwl77"><b>Creating</b> the draw is actually quite difficult; one size does not fit all! Hence, for now,
      this tool is standalone. It will help you configure your pools and schedule your games, finally
      allowing you to export a draw which you can further modify before importing it again in the publish
      tab.</p> 
    <p class="svelte-1rnwl77"><b>Publishing</b> your draw is as easy as uploading two files (teams.csv, draw.csv) and filling
      in a few details as described below. Once published, all participants will be able to see the draw
      and turn up where and when they need to.</p> 
    <p class="svelte-1rnwl77"><b>Playing</b> While the competition is running, duty teams can keep track of time, score goals,
      give out cards. All they need is a mobile device and internet. Ladders are calculated and updated
      automatically, drastically reducing the work on the day of the competition. Draws and Ladders can
      be printed directly from the site if required.</p>`;

			t33 = space();
			div2 = element("div");

			div2.innerHTML = `<h1 class="title" id="csv">What is .csv?</h1> 
    <p class="svelte-1rnwl77">CSV (Comma Seperated Value) is a simple file format based on commas and rows. Normally you
      would export/save a simple Excel spreadsheet to csv.</p>`;

			t37 = space();
			div3 = element("div");
			h13 = element("h1");
			h13.textContent = "Teams.csv";
			t39 = space();
			p5 = element("p");
			p5.textContent = "This spreadsheet describes the teams and players that make up the competition.";
			t41 = space();
			p6 = element("p");
			p6.textContent = "In this simple example we have described two teams in two different divisions. The player\n      numbers are done based on the columns so by leaving empty columns you can describe a team with\n      the correct player numbers.";
			t43 = space();
			table0 = element("table");

			table0.innerHTML = `<thead class="has-text-weight-light"><td>Division / Pool</td><td>Team Name</td><td>Player Number 1</td><td>Player Number 2</td><td>Player Number 3</td></thead> 
      <tbody><tr><td>A</td><td>Chaos</td><td>Tom</td><td></td><td>John</td></tr> 
        <tr><td>B</td><td>Dolphins</td><td>James</td><td>Will</td><td></td></tr></tbody>`;

			t59 = space();
			create_component(info0.$$.fragment);
			t60 = space();
			p7 = element("p");
			p7.textContent = "In the example above team Chaos will have two players \"Tom\" and \"John\" they will be assigned\n      player numbers 1 & 3 respectively.";
			t62 = space();
			p8 = element("p");
			p8.textContent = "Team names must be uniqe across the whole competition. They are not case sensitive but its\n      nice to be consistent.";
			t64 = space();
			p9 = element("p");
			p9.textContent = "Note this example is not complete as a Division/Pool requires at least two teams. When\n      creating an advanced competition with pools you would only describe the teams and players in\n      their starting pools. Subsequent pools will be automatically filled with the appropriate teams\n      based on their results and your draw.";
			t66 = space();
			div4 = element("div");
			h14 = element("h1");
			h14.textContent = "Draw.csv";
			t68 = space();
			p10 = element("p");
			p10.textContent = "This spreadsheet describes the games that make up your competition.";
			t70 = space();
			p11 = element("p");
			p11.textContent = "In this example we have three games. We've defined the field, division/pool, team1 and team2.\n      The referee and duty columns are optional and can be either team names or free text (specific\n      people). If team names are used some validation will be applied to ensure the team isn't\n      playing at the same time.";
			t72 = space();
			table1 = element("table");

			table1.innerHTML = `<thead class="has-text-weight-light"><td>Date &amp; Time</td><td>Field Number</td><td>Division / Pool</td><td>Team 1</td><td>Team 2</td><td>Ref1</td><td>Ref2</td><td>Duty</td></thead> 
      <tbody><tr><td>2022-08-29 8:38pm</td><td>1</td><td>A</td><td>Chaos</td><td>Mooners</td><td>Templestowe</td><td>Templestowe</td><td>Templestowe</td></tr> 
        <tr><td>2022-08-29 8:58pm</td><td>1</td><td>B</td><td>Dolphins</td><td>Explorers</td><td>Templestowe</td><td>Templestowe</td><td>Templestowe</td></tr> 
        <tr><td>2022-08-29 9:18pm</td><td>1</td><td>A</td><td>Chiefs</td><td>Knights</td><td>Chaos</td><td>Chaos</td><td>Chaos</td></tr></tbody>`;

			t108 = space();
			create_component(info1.$$.fragment);
			t109 = space();
			p12 = element("p");
			p12.textContent = "Team names aren't case sensitive but must match teams defined in the teams.csv or be valid\n      placeholders.";
			t111 = space();
			p13 = element("p");
			p13.textContent = "Field Number is the swimming pool the game is played on. Not to be confused with pools as in\n      divisions where we are talking about groups of teams for the purposes of splitting up a\n      division and defining the draw. This is a simple number e.g. 1, 2, or 3.";
			t113 = space();
			h40 = element("h4");
			h40.textContent = "Time format";
			t115 = space();
			p14 = element("p");

			p14.innerHTML = `It&#39;s critical that this time format is followed precisely &quot;<b>YYYY-MM-DD HH:MMam/pm</b>&quot;. If
      the formats aren&#39;t right validation may catch it or you may end up with incorrect times for
      your games. These times are used to calculate/validate clashes between teams playing as well
      as to display and sort the draw with sections at meaningful intervals. The competition&#39;s
      timezone is used when processing these strings into dates so that accurate UTC dates are
      stored in the database and the draw displays local (to the browser) dates when viewing.`;

			t119 = space();
			h41 = element("h4");
			h41.textContent = "Placeholders";
			t121 = space();
			p15 = element("p");
			p15.textContent = "In order to define a draw completely you need some way to define games that are based on the\n      results of previous games. For example who will play in the finals? This is not known until\n      all the other games have been completed.";
			t123 = space();
			p16 = element("p");

			p16.innerHTML = `Placeholders give us this ability. This is the format &quot;<b>_rank::pool/division_</b>&quot; e.g.
      &quot;_1st::A_&quot; or &quot;_2nd::B_&quot; this team placeholder will be substituted for the team at the top of
      the &quot;A&quot; ladder or 2nd from the top in the &quot;B&quot; ladder when the division/pool has finished its
      last game. Placeholders are visible as is &quot;_1st::A_&quot; in the draw until the teams are known
      (all the games in the pool are complete) at which point the actual team name will drop in
      automatically.`;

			t127 = space();
			p17 = element("p");
			p17.textContent = "Below are some examples of how we use placeholders to define different draws.";
			t129 = space();
			h42 = element("h4");
			h42.textContent = "Finals";
			t131 = space();
			p18 = element("p");
			p18.textContent = "To define an A grade final we might add a game/row like this to our draw.csv:";
			t133 = space();
			table2 = element("table");

			table2.innerHTML = `<thead class="has-text-weight-light"><td>Date &amp; Time</td><td>Field Number</td><td>Division / Pool</td><td>Team 1</td><td>Team 2</td><td>Ref1</td><td>Ref2</td><td>Duty</td></thead> 
      <tbody><tr><td>2022-08-29 8:38pm</td><td>1</td><td>Final A</td><td>_1st::A_</td><td>_2nd::A_</td><td>_3rd::B_</td><td>_3rd::B_</td><td>_4th::B_</td></tr></tbody>`;

			t151 = space();
			p19 = element("p");
			p19.textContent = "Here we are defining a new pool \"Final A\" that takes its teams from pool/division \"A\".\n      \"_1st::A_\" will be automatically substituted for the top team in \"A\". So depending on the\n      ladder once all games in that pool are complete, teams will drop into these slots as required.\n      Here B grade slots may drop in first as their division may finish first or vice versa.";
			t153 = space();
			h43 = element("h4");
			h43.textContent = "Semi Finals";
			t155 = space();
			p20 = element("p");
			p20.textContent = "Here we can see how pools can flow from one to another; A -> Semi A -> Final A";
			t157 = space();
			table3 = element("table");

			table3.innerHTML = `<thead class="has-text-weight-light"><td>Date &amp; Time</td><td>Field Number</td><td>Division / Pool</td><td>Team 1</td><td>Team 2</td><td>Ref1</td><td>Ref2</td><td>Duty</td></thead> 
      <tbody><tr><td>2022-08-29 8:38pm</td><td>1</td><td>Semi A</td><td>_1st::A_</td><td>_4th::A_</td><td>_3rd::B_</td><td>_3rd::B_</td><td>_4th::B_</td></tr> 
        <tr><td>2022-08-29 8:38pm</td><td>1</td><td>Semi A</td><td>_2nd::A_</td><td>_3rd::A_</td><td>_3rd::B_</td><td>_3rd::B_</td><td>_4th::B_</td></tr> 
        <tr><td>2022-08-29 8:38pm</td><td>1</td><td>Final A</td><td>_1st::Semi A_</td><td>_2nd::Semi A_</td><td>_3rd::B_</td><td>_3rd::B_</td><td>_4th::B_</td></tr></tbody>`;

			t193 = space();
			p21 = element("p");
			p21.textContent = "Above we have defined two new pools. The first pool is \"Semi A\" it takes teams from \"A\" based\n      on their rank in the ladder when the last game in that pool is finished. The second pool is\n      \"Final A\" which takes its teams from the \"Semi A\" pool based on the results there.";
			t195 = space();
			h44 = element("h4");
			h44.textContent = "General Pools";
			t197 = space();
			p22 = element("p");
			p22.textContent = "Often large competitions don't have time to round robin all teams in a division. A solution to\n      this is to break up divisions into pools. e.g. \"A Grade\" becomes \"A1\" and \"A2\". So if you had\n      8 teams in \"A Grade\" you end up with two pools of 4. These can then flow into a second round\n      where the top half of each pool gets to play together and the bottom half also play together.\n      The top half could then flow into finals or semi finals, etc.";
			t199 = space();
			p23 = element("p");
			p23.textContent = "In this case your teams.csv would not have a division \"A Grade\". It would only define the\n      teams in each of the starting pools. So it would define the teams in \"A1\" and \"A2\" instead.\n      The 2nd round pools \"A Top\", \"A Bottom\" would only be defined in the draw and teams would flow\n      into them based on the placeholders you have defined.";
			t201 = space();
			p24 = element("p");
			p24.textContent = "Yes! This is fiddly and easy to mess up. There is some validation however the dream is to find\n      a simple way to define and visualise these pools and with a click of a button a valid working\n      draw will pop out but we're not there yet.";
			attr(h10, "class", "title");
			attr(a0, "href", "#goal");
			attr(li0, "class", "svelte-1rnwl77");
			attr(a1, "href", "#csv");
			attr(li1, "class", "svelte-1rnwl77");
			attr(a2, "href", "#teams");
			attr(li2, "class", "svelte-1rnwl77");
			attr(li3, "class", "svelte-1rnwl77");
			attr(a3, "href", "#draw");
			attr(a4, "href", "#time");
			attr(li4, "class", "svelte-1rnwl77");
			attr(a5, "href", "#placeholders");
			attr(li5, "class", "svelte-1rnwl77");
			attr(a6, "href", "#finals");
			attr(li6, "class", "svelte-1rnwl77");
			attr(a7, "href", "#semi-finals");
			attr(li7, "class", "svelte-1rnwl77");
			attr(a8, "href", "#general-pools");
			attr(li8, "class", "svelte-1rnwl77");
			attr(ul0, "class", "svelte-1rnwl77");
			attr(li9, "class", "svelte-1rnwl77");
			attr(ul1, "class", "svelte-1rnwl77");
			attr(div0, "class", "section svelte-1rnwl77");
			attr(div1, "class", "section svelte-1rnwl77");
			attr(div2, "class", "section svelte-1rnwl77");
			attr(h13, "class", "title");
			attr(h13, "id", "teams");
			attr(p5, "class", "svelte-1rnwl77");
			attr(p6, "class", "svelte-1rnwl77");
			attr(table0, "class", "table");
			attr(p7, "class", "mt-2 svelte-1rnwl77");
			attr(p8, "class", "svelte-1rnwl77");
			attr(p9, "class", "svelte-1rnwl77");
			attr(div3, "class", "section svelte-1rnwl77");
			attr(h14, "class", "title");
			attr(h14, "id", "draw");
			attr(p10, "class", "svelte-1rnwl77");
			attr(p11, "class", "svelte-1rnwl77");
			attr(table1, "class", "table");
			attr(p12, "class", "mt-2 svelte-1rnwl77");
			attr(p13, "class", "svelte-1rnwl77");
			attr(h40, "class", "title is-5 mb-1 mt-4");
			attr(h40, "id", "time");
			attr(p14, "class", "svelte-1rnwl77");
			attr(h41, "class", "title is-5 mb-1 mt-4");
			attr(h41, "id", "placeholders");
			attr(p15, "class", "svelte-1rnwl77");
			attr(p16, "class", "svelte-1rnwl77");
			attr(p17, "class", "svelte-1rnwl77");
			attr(h42, "class", "title is-5 mb-1 mt-4");
			attr(h42, "id", "finals");
			attr(p18, "class", "svelte-1rnwl77");
			attr(table2, "class", "table");
			attr(p19, "class", "svelte-1rnwl77");
			attr(h43, "class", "title is-5 mb-1 mt-4");
			attr(h43, "id", "semi-finals");
			attr(p20, "class", "svelte-1rnwl77");
			attr(table3, "class", "table");
			attr(p21, "class", "svelte-1rnwl77");
			attr(h44, "class", "title is-5 mb-1 mt-4");
			attr(h44, "id", "general-pools");
			attr(p22, "class", "svelte-1rnwl77");
			attr(p23, "class", "svelte-1rnwl77");
			attr(p24, "class", "svelte-1rnwl77");
			attr(div4, "class", "section svelte-1rnwl77");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div0);
			append(div0, h10);
			append(div0, t1);
			append(div0, ul1);
			append(ul1, li0);
			append(li0, a0);
			append(ul1, t3);
			append(ul1, li1);
			append(li1, a1);
			append(ul1, t5);
			append(ul1, li2);
			append(li2, a2);
			append(ul1, t7);
			append(ul1, li3);
			append(ul1, t8);
			append(ul1, li9);
			append(li9, a3);
			append(li9, t10);
			append(li9, ul0);
			append(ul0, li4);
			append(li4, a4);
			append(ul0, t12);
			append(ul0, li5);
			append(li5, a5);
			append(ul0, t14);
			append(ul0, li6);
			append(li6, a6);
			append(ul0, t16);
			append(ul0, li7);
			append(li7, a7);
			append(ul0, t18);
			append(ul0, li8);
			append(li8, a8);
			append(div5, t20);
			append(div5, div1);
			append(div5, t33);
			append(div5, div2);
			append(div5, t37);
			append(div5, div3);
			append(div3, h13);
			append(div3, t39);
			append(div3, p5);
			append(div3, t41);
			append(div3, p6);
			append(div3, t43);
			append(div3, table0);
			append(div3, t59);
			mount_component(info0, div3, null);
			append(div3, t60);
			append(div3, p7);
			append(div3, t62);
			append(div3, p8);
			append(div3, t64);
			append(div3, p9);
			append(div5, t66);
			append(div5, div4);
			append(div4, h14);
			append(div4, t68);
			append(div4, p10);
			append(div4, t70);
			append(div4, p11);
			append(div4, t72);
			append(div4, table1);
			append(div4, t108);
			mount_component(info1, div4, null);
			append(div4, t109);
			append(div4, p12);
			append(div4, t111);
			append(div4, p13);
			append(div4, t113);
			append(div4, h40);
			append(div4, t115);
			append(div4, p14);
			append(div4, t119);
			append(div4, h41);
			append(div4, t121);
			append(div4, p15);
			append(div4, t123);
			append(div4, p16);
			append(div4, t127);
			append(div4, p17);
			append(div4, t129);
			append(div4, h42);
			append(div4, t131);
			append(div4, p18);
			append(div4, t133);
			append(div4, table2);
			append(div4, t151);
			append(div4, p19);
			append(div4, t153);
			append(div4, h43);
			append(div4, t155);
			append(div4, p20);
			append(div4, t157);
			append(div4, table3);
			append(div4, t193);
			append(div4, p21);
			append(div4, t195);
			append(div4, h44);
			append(div4, t197);
			append(div4, p22);
			append(div4, t199);
			append(div4, p23);
			append(div4, t201);
			append(div4, p24);
			/*div5_binding*/ ctx[11](div5);
			current = true;

			if (!mounted) {
				dispose = [
					listen(a0, "click", stop_propagation(prevent_default(/*click_handler*/ ctx[2]))),
					listen(a1, "click", stop_propagation(prevent_default(/*click_handler_1*/ ctx[3]))),
					listen(a2, "click", stop_propagation(prevent_default(/*click_handler_2*/ ctx[4]))),
					listen(a3, "click", stop_propagation(prevent_default(/*click_handler_3*/ ctx[5]))),
					listen(a4, "click", stop_propagation(prevent_default(/*click_handler_4*/ ctx[6]))),
					listen(a5, "click", stop_propagation(prevent_default(/*click_handler_5*/ ctx[7]))),
					listen(a6, "click", stop_propagation(prevent_default(/*click_handler_6*/ ctx[8]))),
					listen(a7, "click", stop_propagation(prevent_default(/*click_handler_7*/ ctx[9]))),
					listen(a8, "click", stop_propagation(prevent_default(/*click_handler_8*/ ctx[10])))
				];

				mounted = true;
			}
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(info0.$$.fragment, local);
			transition_in(info1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(info0.$$.fragment, local);
			transition_out(info1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div5);
			destroy_component(info0);
			destroy_component(info1);
			/*div5_binding*/ ctx[11](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let helpPage;

	const scrollToSection = async section => {
		helpPage.querySelector(`#${section}`).scrollIntoView({ behavior: "smooth", block: "center" });
	};

	const click_handler = () => scrollToSection("goal");
	const click_handler_1 = () => scrollToSection("csv");
	const click_handler_2 = () => scrollToSection("teams");
	const click_handler_3 = () => scrollToSection("draw");
	const click_handler_4 = () => scrollToSection("time");
	const click_handler_5 = () => scrollToSection("placeholders");
	const click_handler_6 = () => scrollToSection("finals");
	const click_handler_7 = () => scrollToSection("semi-finals");
	const click_handler_8 = () => scrollToSection("general-pools");

	function div5_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			helpPage = $$value;
			$$invalidate(0, helpPage);
		});
	}

	return [
		helpPage,
		scrollToSection,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		click_handler_4,
		click_handler_5,
		click_handler_6,
		click_handler_7,
		click_handler_8,
		div5_binding
	];
}

class Help extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Help;